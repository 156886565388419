@import './variables.scss';
@import './responsive.scss';
@import 'normalize.css';

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   scroll-behavior: smooth;
}

body {
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizespeed;
   font-size: 0.9rem;
   background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.25)), url(../assets/images/bth-bg.png);
   background-size: cover;
   background-position: center;
   overflow-x: hidden;
}

h1 {
   letter-spacing: 0.5px;
   font-size: 1.3rem;
}

h2 {
   letter-spacing: 0.5px;
   font-size: 1.2rem;
}

h3 {
   letter-spacing: 0.5px;
   font-size: 1rem;
}

span {
   font-size: 0.9rem;
   letter-spacing: 0.5px;
}

ul {
   list-style-type: none;
}

a {
   text-decoration: none;
   color: black;
}

select {
   outline: none;
   border: none;
   padding: 4px 8px;
}
