@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

$ncirc: 2;
$color: #ff4c3b;

.mainContainer {
   width: 100%;
   height: 100dvh;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;

   img {
      position: absolute;
   }
}

.circleContainer {
   width: 350px;
   height: 350px;
   border-radius: 100%;
   animation: spin 5s infinite linear;
}

.circle {
   width: 100%;
   height: 100%;
   position: absolute;
   border-radius: 100%;
}

.inner {
   width: 100%;
   height: 100%;
   border-radius: 100%;
   border: 5px solid transparentize($color, 0.3);
   border-right: none;
   border-top: none;
   background-clip: padding;
   box-shadow: inset 0px 0px 10px transparentize($color, 0.85);
}

@keyframes spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

@for $i from 0 through $ncirc {
   .circle:nth-of-type(#{$i}) {
      transform: rotate($i * 70deg);
   }
   .inner {
      animation: spin 2s infinite linear;
   }
}
