$black: #000;
$white: #fff;

$bg-primary: linear-gradient(106.37deg, #ffe1bc 29.63%, #ffcfd1 51.55%, #f3c6f1 90.85%);
$bg-secondary: rgba(255, 255, 255, 1);

$color-lightgray: #d3d3d3;
$color-lighter: #ebebeb;

$color-red-warning: #d93025;
$color-red-lighter: #ffeded;

$btn-fill: #ff4c3b;
$btn-fill-hover: #ff230a;
$btn-weakFill: #0000000d;
$btn-weakFill-hover: #ffffff8c;

$box-shadow-primary:
   0 1px 2px 0 rgba(60, 64, 67, 0.3),
   0 0 3px 1px rgba(60, 64, 67, 0.15);
$box-shadow-secondary: 0px 0px 10px #00000033;
$box-shadow-third:
   0 1px 2px 0 rgba(60, 65, 70, 0.3),
   0 1px 3px 1px rgba(60, 65, 70, 0.15);

$text-light: rgba(0, 0, 0, 0.75);

$offset: 187;
$duration: 1.4s;

$main-blue: rgb(26, 115, 232);
$main-white: rgb(255, 255, 255);
$main-dark: rgb(0, 0, 0);
$main-dark-light: rgba(0, 0, 0, 0.75);
$main-dark-lighter: rgba(0, 0, 0, 0.25);
$main-ease: cubic-bezier(0.65, 0.05, 0.35, 1);
